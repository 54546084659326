import { Box, Card, CardContent, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import  React,{
    useState,
    useEffect
} from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
const unitJson={
    name:"Jonathan",
    streetAddress:"803 W Memorial Blvd, Lakeland, FL 33815"
}
function UnitCard({}) {
    return(
        <>
        <Card sx={{ maxWidth: 275 }}>
            <CardContent>
                <Box sx={{display:"flex",justifyContent:"space-around",padding:1.5}}>
                    <h6>{unitJson.name}<br></br> {unitJson.streetAddress} </h6>
                </Box>
                <hr></hr>

            </CardContent>
        </Card>
        <br></br>
        <Card sx={{ maxWidth: 275 }}>
            <CardContent>
                <Box sx={{display:"flex",justifyContent:"space-between",padding:1}}>
                    <h6>Current Tenant</h6>
                </Box>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountCircleOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Saranya C Pathuri" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <EmailOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="scp@gmail.com" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <PhoneAndroidOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="(470) 836-2073" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                </Box>

            </CardContent>
        </Card>
        </>

    );
}
export default UnitCard;
import React, { useState,useEffect } from 'react';
import { Grid, Paper, Typography,Box, Card, 
    CardContent, List, ListItem, ListItemButton, 
    ListItemIcon, ListItemText, 
    Divider,Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Icon,
    styled,
    FormControl,
    Select,
    OutlinedInput,
    MenuItem,
    Button,
    TextField} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllUnitsByPropertyIdInDB } from '../UnitsFunctions';
import { green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import CardAnalysis from '../AdminDashboard/CardAnalysis';
import { getAllPropertiesInDB } from '../PropertyFunctions';
import { getActiveTenanciesByPropertyIdInDB } from '../TenancyFunctions';
import { getPaymentScheduleByTenancyIdInDB } from '../PaymentScheduleFunctions';
import { getCustomerInfoByTenancyIdInDB } from '../CustomerFunctions';
import { dateDifference } from '../CommonFunctions';
import Header from '../Header';
import EditIcon from '@mui/icons-material/Edit';
import AddUnit from "../Property/AddUnit";
import AddTenant from './AddTenant';



  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
const LeaseGrid = ({properties=[]}) => {
    
    const {propertyId,unitId} = useParams();
    const [propertyInfo,setPropertyInfo] = useState({
        units:[],
        tenancies:[]
    });
    const [customers,setCustomers] = useState([]);
    const [paymentSchedule, setPaymentSchedule] = useState([]);
    const [units,setUnits] = useState([])
    const [scheduledPayments,setScheduledPayment] = useState([]);
    const theme = useTheme();
    const [currentProperty,setCurrentProperty] = useState({});
    const [currentUnit,setCurrentUnit] = useState({});
    const [tenancies,setTenancies] = useState([]);
    const [currentTenancy,setCurrentTenancy] = useState({});
    const [showAddUnitModal, setShowAddUnitModal] = useState(false);
    const navigate=useNavigate();
    // console.log(propertyId);
    const handleChange = (event) => {
        console.log("calling on change");
        setCurrentProperty(
          // On autofill we get a stringified value.
          event.target.value
        );
      };

    useEffect(() => {
        if(currentProperty && currentProperty.id){
            getUnitsAndTenanciesInfoByPropertyId(currentProperty.id);
           
        }
    }, [currentProperty.id]);
    useEffect(() => {
        console.log("rendering");
        console.log(properties)
        if(propertyId){
            console.log(typeof propertyId)
            if(properties){
                console.log("rendering 2")
                filterByPropertyId(propertyId)
                
                // 
            }
        }
    }, [properties]);
    
    useEffect(() => {
        if(currentUnit.currentTenancyId!=null){

            const tenancies=propertyInfo.tenancies.filter((tenancy)=>
                tenancy.id===currentUnit.currentTenancyId)
            // console.log(tenancies[0])
            setCurrentTenancy(tenancies[0])
        }
        
        
    }, [currentUnit.currentTenancyId]);

    useEffect(() => {

        if(currentTenancy && currentTenancy.id){
            getCustomerInfoByTenancyId(currentTenancy.id);
            getPaymentScheduleByTenancyId(currentTenancy.id);
        }
    
    }, [currentTenancy.id]);

    
    const getCustomerInfoByTenancyId= async(tenancyId) =>{
        try{
            const response = await getCustomerInfoByTenancyIdInDB(tenancyId);
            if(response){
                setCustomers(response);
            }

        }catch(error){
            console.log(error);
        }
    }
    const getPaymentScheduleByTenancyId= async(tenancyId) =>{
        try{
            const response = await getPaymentScheduleByTenancyIdInDB(tenancyId);
            if(response){
                setPaymentSchedule(response);
            }

        }catch(error){
            console.log(error);
        }
    }
    function filterByPropertyId(propertyId){
        const selectedProperty = properties.find(
            (property) => property.id == propertyId
          );
        if(selectedProperty){
            setCurrentProperty(selectedProperty)
        }
    }
    
    const getUnitsAndTenanciesInfoByPropertyId = async (propertyId) =>{
        try{
            const unitsInfo= await getAllUnitsByPropertyIdInDB(propertyId);
            if(unitsInfo){
                const tenanciesInfo = await getActiveTenanciesByPropertyIdInDB(propertyId);
                if(tenanciesInfo){
                    let unitDict={};
                    for(let itr in unitsInfo){
                        let currentRow=unitsInfo[itr];
                        currentRow["occupancyStatus"] = "vacant";
                        currentRow.currentTenancyId=null;
                        unitDict[currentRow.id] = currentRow;
                    }
                    for(let itr in tenanciesInfo){
                        let currentRow = tenanciesInfo[itr];
                        if(currentRow.unitId in unitDict)
                        {
                            unitDict[currentRow.unitId].occupancyStatus = "rented";
                            unitDict[currentRow.unitId].currentTenancyId = currentRow.id;
                        }
                    }
                    let unitArray=[];
                    for(let key in unitDict){
                        unitArray.push(unitDict[key]);
                    }
                    if(unitId){
                        const selectedUnit = unitArray.find((unit)=>
                        unit.id == unitId);
                        if(selectedUnit){
                            setCurrentUnit(selectedUnit)
                        }
                    }
                    setPropertyInfo({
                        units:unitArray,
                        tenancies:tenanciesInfo
                    })

                }
            }

        }catch(error){
            console.log(error)
        }
    }
    const getAllUnitsByPropertyId = async(propertyId)=>{
        try{

            const response = await getAllUnitsByPropertyIdInDB(propertyId);
            if(response)
            {
                // console.log()
                setUnits(response)
            }

        }
        catch(error){
            console.log(error)
        }
    }
    const getActiveTenanciesByPropertyId = async(propertyId) =>{
        try{
            const response = await getActiveTenanciesByPropertyIdInDB(propertyId);
            if(response)
            {
                setTenancies(response);
            }
        }
        catch(error){
            console.log(error);
        }
    }
    
  return (
    <>
    <Header/>
    {currentUnit.currentTenancyId ? 
    <Box sx={{display:"flex", justifyContent:"space-around",mt:2}}>
        <Button></Button>
    <Button variant='outlined' onClick={(e)=>
        navigate(`/property/${propertyId}/units/${currentUnit.id}/lease/${currentUnit.currentTenancyId}`)}>
            Edit</Button></Box> : <Box sx={{display:"flex", justifyContent:"space-around",mt:2}}>
        <Button></Button>
    <Button variant='outlined' onClick={(e)=>
        navigate(`/property/${propertyId}/units/${currentUnit.id}/lease`)}>
            Create Lease</Button></Box>}
    <Grid container spacing={2} style={{ height: '30rem', padding: '16px' }}>
      {/* Left 1/4 */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={3} style={{ height: 'auto', padding: '16px' }}>
            
        <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
        <Select
          
        //   displayEmpty
          value={currentProperty.id || ''}
        //   onChange={handleChange}
          onChange={(e) => {
            filterByPropertyId(e.target.value)
          }}
          input={<OutlinedInput />}
          
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {/* <MenuItem disabled value="">
            <em>Select a Property</em>
          </MenuItem> */}
          {properties.map((property) => (
            <MenuItem
              key={property.id}
              value={property.id}
            >
              {property.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          {propertyInfo.units.map((unitJson) =>{
    
            return <>
            <Box 
            onClick={(e)=>{
                setCurrentUnit(unitJson)
                // console.log(unitJson)
            }}
            sx={{ padding: 1, backgroundColor: currentUnit.id===unitJson.id ? "#cceeff" :"white" }}>
            
            <Grid container spacing={2}>
              {/* First Grid: Left Part */}
              <Grid item xs={10} sm={10} md={10}>
              <Typography sx={{paddingBottom:0.6}}>
              <Typography variant="body2">
                {unitJson.occupancyStatus==="rented" ? <img rel="apple-touch-icon" src="/green-icon.png" width="7%" height="7%" />
                : unitJson.occupancyStatus==="vacant" ? <img rel="apple-touch-icon" src="/red-icon.png" width="7%" height="7%" />
            : <img rel="apple-touch-icon" src="/yellow-icon.png" width="7%" height="7%" />}
               {unitJson.streetAddress}
                </Typography>
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>
      
              {/* Second Grid: Right Part */}
              <Grid item xs={2} sm={2} md={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Typography sx={{paddingBottom:0.6}}>
                 <h5> $1,700</h5>
                </Typography>
                {unitJson.occupancyStatus==="rented" ? 
                <Typography variant="body2" align="right" color="green">
                  Rented
                </Typography> : <Typography variant="body2" align="right" color="red">
                  Vacant
                </Typography>}
              </Grid>
            </Grid>
            
          </Box>
          <Divider/>
          </>
          })}
          
    </Paper>
    
      </Grid>

      {/* Center 2/4 */}
      <Grid item xs={12} sm={12} md={6} container direction="column" spacing={2} width="auto">
        <Grid item>
          <Paper elevation={3} style={{ padding: '16px' }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={12} sm={6} md={3.5}>
                <Item><CardAnalysis title="Lease ends in" isLoss={false} 
                count={currentUnit.currentTenancyId ? dateDifference(currentTenancy.checkout,new Date()) : '-'} 
                
                isMoney={false}  isNumber={false}
                subTitle="days" navigateURL={`/property/${propertyId}/units`} /></Item>
              </Grid>
              <Grid item xs={12} sm={6} md={5}> 
                <Item><CardAnalysis title="Maintenance requests" isLoss={false} count={10} isMoney={false} 
                 subTitle="open" navigateURL={`/property/${propertyId}/repairs`}/></Item>
              </Grid>
              <Grid item xs={12} sm={6} md={3.5}>
                <Item><CardAnalysis title="Rent due in" isLoss={true} isMoney={false} 
                count={currentUnit.currentTenancyId ? -3 : '-'}
                subTitle="days" navigateURL={`/property/${propertyId}/monthlyPaymentReport`}/></Item>
              </Grid>
          </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3} style={{ height: "320px", padding: '16px' }}>
            <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10}>
                    <Typography variant="h6">Payment Schedule</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                    <EditIcon/>
                </Grid>
            </Grid>
            
            
            <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
            <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell> Due Date</TableCell>
                                <TableCell>Rent</TableCell>
                                {/* <TableCell>Amount</TableCell> */}
                                <TableCell>tax</TableCell>
                                <TableCell>Total Rent Due</TableCell>
                                <TableCell>Rent Received</TableCell>
                                <TableCell>Received Date</TableCell>
                                <TableCell>Balance</TableCell>



                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {paymentSchedule.map((row) => (
                            <TableRow>
                               
                                <TableCell>{row.dueDate}</TableCell>
                                <TableCell>{row.rent}</TableCell>
                                {/* <TableCell>{row.deposit}</TableCell> */}
                                <TableCell>{row.tax}</TableCell>
                                <TableCell>{parseInt(row.rent) + parseInt(row.deposit || 0) + parseInt(row.tax || 0)}</TableCell>
                                <TableCell>{row.description}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table> 
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Right 1/4 */}
      <Grid item xs={12} sm={6} md={3} container direction="column" spacing={2}>
        <Grid item>
            {/* <Typography variant="h6">Right Upper Section</Typography> */}
            <Card sx={{ maxWidth: 275 }}>
            <CardContent>
                <Box sx={{display:"flex",justifyContent:"space-around",padding:1.5}}>
                    <h6>{currentUnit.name}<br></br> {currentUnit.streetAddress} </h6>
                    <EditIcon onClick={(e)=>{
                        setShowAddUnitModal(true);
                    }}/>
                </Box>
                <hr></hr>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentUnit.currentTenancyId ? 
                                currentTenancy.checkin + ' - ' + currentTenancy.checkout : "No Lease"} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <EmailOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentUnit.currentTenancyId ? 
                                '$' + currentTenancy.rate + ' ' + currentTenancy.rentalType : '-'} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <EmailOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentUnit.currentTenancyId ? 
                                'Deposit $'+currentTenancy.deposit : '-' } />
                                </ListItemButton>
                            </ListItem>
                           
                        </List>
                    </nav>
                </Box>

            </CardContent>
        </Card>
        </Grid>
        <Grid item>
            {customers.length>0 ?
            <CustomerCard customers={customers} setCustomers={setCustomers}/> : null}
          {/* <Paper elevation={3} style={{ height: '50%', padding: '16px' }}> */}
            {/* <Typography variant="h6">Right Lower Section</Typography> */}
          {/* </Paper> */}
          
        </Grid>
      </Grid>
    </Grid>
    {showAddUnitModal && <AddUnit showAddUnitModal={showAddUnitModal} 
    setShowAddUnitModal={setShowAddUnitModal}
    unitRows={propertyInfo.units} unitJson={currentUnit}      />}
    </>
  );
};

const CustomerCard =({customers,setCustomers})=>{
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showAddTenantModal, setShowAddTenantModal] =useState(false);
    const handleNext = () => {
        if (currentIndex < customers.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      };
    
      // Handler for navigating to the previous tenant
      const handleBack = () => {
        if (currentIndex > 0) {
          setCurrentIndex((prevIndex) => prevIndex - 1);
        }
      };
       // Get the current tenant details
  const currentTenant = customers[currentIndex];
    return (
        <Card sx={{ maxWidth: 275 }}>
            <CardContent>
                <Box sx={{display:"flex",justifyContent:"space-between",padding:1}}>
                    <h6>Current Tenant</h6>
                    <EditIcon onClick={(e)=>setShowAddTenantModal(true)}/>
                </Box>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountCircleOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentTenant.fname + ' ' +currentTenant.lname} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <EmailOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentTenant.email} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <PhoneAndroidOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentTenant.phone} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button variant="outlined" disabled={currentIndex === 0} onClick={handleBack}>
          Back
        </Button>
        <Button variant="outlined" disabled={currentIndex === customers.length - 1} onClick={handleNext}>
          Next
        </Button>
      </Box>
                </Box>

            </CardContent>
        {showAddTenantModal && <AddTenant showAddTenantModal={showAddTenantModal}
        setShowAddTenantModal={setShowAddTenantModal} rows={customers} setRows={setCustomers}
        customer={currentTenant}/>}
        </Card>
    )
}
export default LeaseGrid;

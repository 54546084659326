
import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Snackbar,
    Alert
    } from '@mui/material';
import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
  } from '@mui/x-data-grid';
  import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
  } from '@mui/x-data-grid-generator';
  import {getAllUnitsByPropertyIdInDB} from "../UnitsFunctions"

const UnitInfo = ({ units,setUnits,propertyJson }) => {
    const {propertyId}=useParams();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [showAddUnitModal,setShowAddUnitModal]=useState(false)
  const columns = [
    { field: 'id', headerName: 'Id', width: 40, editable: false },
    { field: 'name', headerName: 'Name', width: 180, editable: true },
    { field: 'streetAddress', headerName: 'Address', width: 300, editable: true },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }
  
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
    }
  ];
  
  // const [units, setUnits] = useState(units);
  let [rowModesModel, setRowModesModel] = useState({});
  const url = process.env.REACT_APP_SERVER_URL;
  useEffect(() => {
    if(propertyId){
      getAllUnitsByPropertyId(propertyId);
    }
    }, [propertyId]);
  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
    console.log(units)
  };
  

  const sortedunits = units.slice().sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] - b[orderBy];
    } else {
      return b[orderBy] - a[orderBy];
    }
  });
  const handleRowModesModelChange = (newRowModesModel) => {
    // console.log("row change!!!")
    setRowModesModel(newRowModesModel);
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setUnits(units.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };
// Handling Editing on Data Grid
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
//   Updating the existing units in our data-units
    const processRowUpdate = (newRow) => {
      // alert("calling when!!!")
        const updatedRow = { ...newRow, isNew: false };
        setUnits(units.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };
    const getAllUnitsByPropertyId = async(propertyId) =>{
      try{
          const response=await getAllUnitsByPropertyIdInDB(propertyId);
          if(response){
              setUnits(response);
          }

      }
      catch(error)
      {
          console.log(error)
      }
  }

  return (
    <>
    <h6>{propertyJson.streetAddress + ', ' + propertyJson.city + ', ' +
     propertyJson.state +' - '+ propertyJson.zipCode} <a onClick={(e)=>{
      navigator.clipboard.writeText(propertyJson.streetAddress + ', ' + propertyJson.city + ', ' +
      propertyJson.state +' - '+ propertyJson.zipCode)
     }}>Copy</a></h6>
     <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={units}
        columns={columns}
        editMode="row"
        // onRowDoubleClick={processRowUpdate}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { units,setUnits,setRowModesModel },
        }}
      />
    </Box>
    
    </>
  );
};


function EditToolbar(props) {
    const { units, setUnits, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = -1* (units.length + 1);
      setUnits((oldUnits) => [...oldUnits, { id, name: 'Unit'+id, streetAddress: '', isNew: true }]);
      
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
      }));
    };
  
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      </GridToolbarContainer>
    );
  }

function DisplayMessages(){
    
    return (
        <Snackbar open={true} autoHideDuration={6000}>
  <Alert
    severity="success"
    variant="filled"
    sx={{ width: '100%' }}
  >
    This is a success Alert inside a Snackbar!
  </Alert>
</Snackbar>
    );
}
export default UnitInfo;

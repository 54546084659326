import React, { useState,useEffect } from 'react';
import LeaseGrid from './LeaseGrid';
import { getAllPropertiesInDB } from '../PropertyFunctions';
const LeaseGridProperty = () => {
        const [properties,setProperties] = useState([]);
    
    useEffect(() => {
        getAllProperties();
    }, []);
    
    const getAllProperties = async() =>{
        try{
            const response = await getAllPropertiesInDB();
            if(response){
                setProperties(response);
            }
        }
        catch(error){
            console.log(error)
        }
    }
    
    return (
        <LeaseGrid properties={properties}/>

    );
}
export default LeaseGridProperty;
import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper
    
} from "@mui/material";
  import CancelIcon from '@mui/icons-material/Close';
import { calculateTotalTaxPer } from "../CommonFunctions";
const TaxCalculation = ({tenancyJson,unitJson,showTaxCalculationModal,setShowTaxCalculationModal}) => {
      const calculateTotalTax=()=>{
        if(tenancyJson){
            let totalTax= calculateTotalTaxPer(tenancyJson)
            return  (totalTax * tenancyJson.expectedRent)/100;
        }
      }
      const handleCloseTaxCalculationPopup=()=>{
        setShowTaxCalculationModal(false)
      }
      useEffect(() => {
        

      }, [unitJson,tenancyJson]);
      
    return (
        <>
      <Dialog
        open={showTaxCalculationModal}
        onClose={handleCloseTaxCalculationPopup}
        maxWidth="lg"
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton aria-label="close" onClick={handleCloseTaxCalculationPopup}>
            <CancelIcon />
        </IconButton></DialogTitle>
        <DialogContent>
          <DialogContentText> 
          </DialogContentText>
          <TableContainer component={Paper} >
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              Tax Details
            </TableCell>
            <TableCell align="right">Percentage</TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor:"#f2f2f2"}}>
            <TableCell align="right">Sales Tax</TableCell>
            <TableCell align="right">Tourism Tax</TableCell>
            <TableCell align="right">Surface Tax</TableCell>
            <TableCell align="right">Other Taxes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
              <TableCell align="right"><b>{tenancyJson.salesTax || 0}%</b></TableCell>
              <TableCell align="right"><b>{tenancyJson.tourismTax || 0}%</b></TableCell>
              <TableCell align="right"><b>{tenancyJson.surfaceTax || 0}%</b></TableCell>
              <TableCell align="right"><b>{tenancyJson.otherTax || 0}%</b></TableCell>
            </TableRow>
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2}>Expected Rent</TableCell>
            <TableCell align="right">
                <b><NumericFormat value={tenancyJson.expectedRent} thousandSeparator prefix={'$'} displayType="text"/>
                </b>
                </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Tax</TableCell>
            <TableCell align="right"><b style={{color:"#0066cc"}}>{`${parseInt(tenancyJson.salesTax || 0) + 
                        parseInt(tenancyJson.tourismTax || 0) + 
                        parseInt(tenancyJson.surfaceTax || 0) +
                        parseInt(tenancyJson.otherTax || 0)} %`}</b></TableCell>
            <TableCell align="right">
                <NumericFormat 
                value={calculateTotalTax()} thousandSeparator prefix={'$'} 
                displayType="text"/>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align="right">
                <NumericFormat 
                value={tenancyJson.expectedRent + calculateTotalTax()} thousandSeparator prefix={'$'} 
                displayType="text"/>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
        
        </DialogContent>
      </Dialog>  
            
        </>
    );

}
export default TaxCalculation;
import React,{useEffect,useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {useNavigate, useParams} from 'react-router-dom';
import { MenuList } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {getUserMail} from "./Auth";
import Cookies from 'js-cookie';
let prevPropertyId=null;
function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElProperty, setAnchorElProperty] = useState(null);
  const [anchorElReport,setAnchorElReport] = useState(null);
  const [anchorElLease,setAnchorElLease] = useState(null);
  const {propertyId}=useParams();
  const [propertyName,setPropertyName]=useState(sessionStorage.getItem('propertyName') || 'No property selected');
  const navigate= useNavigate();
  useEffect(() => {
    if(propertyId && prevPropertyId!=propertyId)
    {
      prevPropertyId=propertyId;
    }
    
  }, [propertyId,prevPropertyId]);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenPropertyMenu = (event) => {
    setAnchorElProperty(event.currentTarget);
  };
  const handleOpenReportMenu = (event) =>{
    setAnchorElReport(event.currentTarget)
  }
  const handleOpenLeaseMenu = (event) =>{
    setAnchorElLease(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClosePropertyMenu = () => {
    setAnchorElProperty(null);
  };
  const handleCloseReportMenu = () => {
    setAnchorElReport(null);
  };
  const handleCloseLeaseMenu = () => {
    setAnchorElLease(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
            onClick={(e)=> navigate(`/properties`)}
           
          >
            BETTER GUESTS <br></br>{propertyName}
          </Typography>
          
          

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem  onClick={(e)=>{
                  handleCloseNavMenu()
                  navigate(`/property/${propertyId}/tenants`);
                  }}>
                  <Typography textAlign="center">Manage Existing Guests</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=>{
                  handleCloseNavMenu()
                  navigate(`/property/${propertyId}/payments`);
                  }}>
                  <Typography textAlign="center">Payments Report</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=>{
                  handleCloseNavMenu()
                  navigate(`/repairs`);
                  }}>
                  <Typography textAlign="center">Repairs</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=>{
                  handleCloseNavMenu()
                  navigate(`/amenities`);
                  }}>
                <Typography textAlign="center">Amenities</Typography>
                </MenuItem>
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            BETTER GUESTS
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
                onClick={(e)=>{
                  handleCloseNavMenu(e);
                  navigate(`/properties`);
                }}
                sx={{ my: 2, color: 'white', display: 'block',marginRight:2 }}
              >
                Home
              </Button>
          <Box sx={{ flexGrow: 0,my:2,marginLeft:2,marginRight:2 }}>
            <Tooltip title="lease menu">
              <Button onClick={(e)=>anchorElLease==null ? handleOpenLeaseMenu(e) : handleCloseLeaseMenu(e)} sx={{ my:2,p: 0,color:"white" }}>
                LEASE MANAGEMENT 
              </Button>
            </Tooltip>
            <Menu
              sx={{my:2, mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElLease}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLease)}
              onClose={handleCloseLeaseMenu}
            >
              <MenuItem  onClick={(e)=>{
                handleCloseLeaseMenu(e);
                
                navigate(`/property/${propertyId}/units`);
                
              }}>
                  <Typography textAlign="center">CHECKIN NEW GUEST</Typography>
                </MenuItem>

                <MenuItem  onClick={(e)=>{
                  handleCloseLeaseMenu(e);
                  navigate(`/property/${propertyId}/tenants`);
                  
                
                }}>
                  <Typography textAlign="center">MANAGE EXISTING GUESTS</Typography>
                </MenuItem>
            </Menu>
          </Box>
              
              <Button
                onClick={(e)=>{
                  handleCloseNavMenu(e);
                  navigate(`/property/${propertyId}/payments`);
                }}
                sx={{ my: 2, color: 'white', display: 'block',marginRight:2 }}
              >
                PAYMENTS
              </Button>
              <Box sx={{ flexGrow: 0,my:2 }}>
            <Tooltip title="reports menu">
              <Button onClick={(e)=>anchorElReport==null ? 
                handleOpenReportMenu(e) : handleCloseReportMenu(e)} sx={{ my:2,p: 0,color:"white" }}>
                Reports 
              </Button>
            </Tooltip>
            <Menu
              sx={{my:2, mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElReport}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElReport)}
              onClose={handleCloseReportMenu}
            >
              <MenuItem  onClick={(e)=>{
                handleCloseReportMenu(e);
                navigate(`/property/${propertyId}/monthlyPaymentReport`);
              }}>
                  <Typography textAlign="center">MONTHLY PAYMENTS REPORT</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=>{
                  handleCloseReportMenu(e);
                  navigate(`/property/${propertyId}/repairs`);
                }}>
                  <Typography textAlign="center">REPAIRS</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=>{
                  handleCloseReportMenu(e);
                  navigate(`/property/${propertyId}/amenities`);
                }}>
                  <Typography textAlign="center">AMENITIES</Typography>
                </MenuItem>
                
                
            </Menu>
          </Box>
          </Box>

          <Box sx={{ flexGrow: 0,marginRight:5 }}>
            <Tooltip title="Change Porperty">
              <Button onClick={(e)=> anchorElUser==null ? handleOpenUserMenu(e) :handleCloseUserMenu(e)} 
                sx={{ p: 0,color:"yellow",fontSize:20 }}>
                {propertyName}
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem  onClick={(e)=> {
                  handleCloseUserMenu(e);
                  navigate(`/property/${propertyId}/units`)

                }}>
                  <Typography textAlign="center">SHOW ALL UNITS</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=> {
                  handleCloseUserMenu(e);
                  navigate(`/properties`)

                }}>
                  <Typography textAlign="center">CHANGE PROPERTY</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=> {
                  handleCloseUserMenu(e);
                  navigate(`/property`)

                }}>
                  <Typography textAlign="center">ADD PROPERTY</Typography>
                </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Change Porperty">
              <Button onClick={(e)=> anchorElProperty==null ? handleOpenPropertyMenu(e) :handleClosePropertyMenu(e)} 
                sx={{ p: 0,color:"white",fontSize:20 }}>
                <ManageAccountsIcon sx={{fontSize:40}}/>
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElProperty}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElProperty)}
              onClose={handleClosePropertyMenu}
            >
              <MenuItem  onClick={(e)=> {

                }}>
                  <Typography textAlign="center">Signed In as {getUserMail()}</Typography>
                </MenuItem>
                <MenuItem  onClick={(e)=> {
                  handleClosePropertyMenu(e);
                  // navigate(`/logout`)
                  Cookies.remove('token');
                  navigate(`/login`)

                }}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
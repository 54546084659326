import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const getPropertyDetailsByIdInDB = async(propertyId) => {
    try{
        const responseForProperty= await axios.get(`${url}/property/${propertyId}`);
        if(responseForProperty && responseForProperty.data && responseForProperty.data.length>0){
          return responseForProperty.data[0];
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};

export const getAllPropertiesInDB = async() => {
    try{
        const responseForProperty= await axios.get(`${url}/property`);
        if(responseForProperty && responseForProperty.data && responseForProperty.data.length>0){
          return responseForProperty.data;
        }
        else{
          // Fix it: Confirm it with all use cases.
          return [];
        }
    }
    catch(error)
    {
        console.log(error);
    }
};



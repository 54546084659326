import axios from "axios";
import { createLeaseActivityLogInDB } from "./LeaseActivityLogFunctions";
const url = process.env.REACT_APP_SERVER_URL;
export const getTenancyInfoByIdInDB = async(tenancyId) =>{
    try{
        const responseForTenancy = await axios.get(`${url}/tenancy/${tenancyId}`);
        console.log(responseForTenancy)
            if(responseForTenancy && responseForTenancy.data && responseForTenancy.data.length>0){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const createTenancyInfoInDB = async(tenancyJson) =>{
    try{
        const responseForTenancy = await axios.post(`${url}/tenancy`,tenancyJson)
            if(responseForTenancy && responseForTenancy.data){
                const leaseActivityLog={
                    tenancyId:responseForTenancy.data.tenancyId,
                    description:`New Lease Created for unit ${tenancyJson.unitId} 
                    from ${tenancyJson.checkin} to ${tenancyJson.checkout}`
                };
                createLeaseActivityLogInDB(leaseActivityLog);

                return responseForTenancy.data;
                
              }
              else{
                return "No Data Found"
            }
        
    }
    catch(error)
    {
        console.log(error)
    }
}
export const updateTenancyInfoInDB = async(tenancyId,tenancyJson) =>{
    try{
        const responseForTenancy = await axios.patch(`${url}/tenancy/${tenancyId}`,tenancyJson);
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const getPropertyInfoByTenancyIdInDB = async(tenancyId) =>{
    try{
        const responseForTenancy = await axios.get(`${url}/tenancy/propertyInfo/${tenancyId}`);
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const getUnitInfoByTenancyIdInDB = async(tenancyId) =>{
    try{
        const responseForTenancy = await axios.get(`${url}/tenancy/unitInfo/${tenancyId}`);
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const getAnyOverLappingLeaseInDB = async(searchStartDate,searchEndDate,unitId) =>{
    try{
        const responseForTenancy = await axios.post(`${url}/tenancy/checkOverLap/${unitId}`,{
            searchStartDate,
            searchEndDate
        });
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getActiveTenanciesByPropertyIdInDB = async(propertyId) =>{
    try{
        const responseForTenancy = await axios.get(`${url}/tenancyByPropertyId/${propertyId}`);
            if(responseForTenancy && responseForTenancy.data){
                return responseForTenancy.data;
              }
              else{
                return [];
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

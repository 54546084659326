import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    FilledInput,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Input,
    TextField,
    InputAdornment,
    Box
} from '@mui/material';
import {calculateMonthsAndDays, calculateWeeksAndDays} from "../CommonFunctions"
import { getAnyOverLappingLeaseInDB } from '../TenancyFunctions';
export default function LeaseInfo({tenancyJson,setTenancyJson}) {
    const [dateDiffJson,setDateDiffJson]=useState({})
    const {propertyId} = useParams();
    const {unitId} =useParams();
    const {tenancyId} = useParams();
    const [overLappingTenancy,setOverLappingTenancy]=useState([]);
    const url = process.env.REACT_APP_SERVER_URL;
    
    useEffect(() => {
        if(tenancyId){
            getTenancyInfoByTenancyId(tenancyId)
        }
        
    
      }, [tenancyId]);

      useEffect(() => {
        if(tenancyJson.rentalType=="monthly")
        setDateDiffJson(calculateMonthsAndDays(tenancyJson.checkin,tenancyJson.checkout));
        else if(tenancyJson.rentalType=="weekly")
        setDateDiffJson(calculateWeeksAndDays(tenancyJson.checkin,tenancyJson.checkout));
        
      }, [tenancyJson.checkin,tenancyJson.checkout,tenancyJson.rentalType]);


      useEffect(() => {
        getAnyOverLappingLease(tenancyJson.checkin,tenancyJson.checkout);
      }, [tenancyJson.checkin,tenancyJson.checkout]);

      const getAnyOverLappingLease = async(checkin,checkout)=>{
        try{
            
            const response = await getAnyOverLappingLeaseInDB(checkin,checkout,unitId);
            if(response){
                console.log(response.length);
                setOverLappingTenancy(response);
            }

        }catch(error){
            console.log(error);
        }
      }

      const setStateValueTenancyJson = (tenancyDetails) =>{
        if(tenancyDetails){
            setTenancyJson({...tenancyJson,
                firstArrivalDate:tenancyDetails.firstArrivalDate,
                checkin:tenancyDetails.checkin,
                checkout:tenancyDetails.checkout,
                rentalType:tenancyDetails.rentalType,
                rate:tenancyDetails.rate,
                deposit:tenancyDetails.deposit,
                unitId:tenancyDetails.unitId,
                tenancyId:tenancyDetails.id,
                actualCheckoutDate:tenancyDetails.actualCheckoutDate,
                description:tenancyDetails.description,
                electricity:tenancyDetails.electricity,
                water:tenancyDetails.water,
                garbage:tenancyDetails.garbage,
                sewage:tenancyDetails.sewage
  
            })
        }
  
    }
    const getTenancyInfoByTenancyId = async(tenancyId) =>{
        try{
            await axios.get(`${url}/tenancy/${tenancyId}`).then((responseForTenancy)=>{
                console.log(responseForTenancy.data);
                setStateValueTenancyJson(responseForTenancy.data[0]);
            })
  
        }catch(error){
            console.log(error);
        }
    }




    
    

  return (
    <>
    <br></br>
    {overLappingTenancy.length>0 ? 
    "There is an already existing lease in the selected period.Click here" : null}
    <br></br>
    {tenancyJson.rentalType=="monthly" ? 
    <label>{dateDiffJson.months + " Months " + dateDiffJson.days + " days"} </label>
    : null}{ tenancyJson.rentalType=="weekly" ? 
    <label>{dateDiffJson.weeks + " Weeks " + dateDiffJson.days + " days"} </label> : null}
    <br></br>
    <Box>
        <div>
    <FormControl sx={{ m: 2, width: '25ch'}} >
    <InputLabel id="demo-simple-select-autowidth-label">LEASE START DATE</InputLabel>
    <Input type="date"
            value={tenancyJson.checkin}
            onChange={(e)=>{
                setTenancyJson({...tenancyJson,checkin: e.target.value,firstArrivalDate:e.target.value});
            }}
        />
    </FormControl>
    

    <FormControl sx={{ m: 2, width: '25ch' }} >
    <InputLabel id="demo-simple-select-autowidth-label">LEASE END DATE</InputLabel>
    <Input type="date"
            value={tenancyJson.checkout}
            onChange={(e)=>{
                // if()
                setTenancyJson({...tenancyJson,checkout:e.target.value })
                
            }}
        />
    </FormControl>
        </div>  
        <div>
        <FormControl sx={{ m: 2, width: '25ch' }} >
        <InputLabel id="demo-simple-select-autowidth-label">Payment Schedule</InputLabel>
        <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            label="Payment Schedule Type"
            value={tenancyJson.rentalType}
            onChange={(e)=>setTenancyJson({...tenancyJson,rentalType: e.target.value})}
        >
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
        </Select>
    </FormControl>  
    { tenancyJson.rentalType === "monthly" ? 
        <FormControl sx={{ m: 3, width: '25ch' }} >
        <InputLabel id="demo-simple-select-autowidth-label">Payment Day of the Month</InputLabel>
        <Input type="number"
            />
        </FormControl> :
        <FormControl sx={{ m: 2, width: '25ch' }} >
            <InputLabel id="demo-simple-select-autowidth-label">Which day payment will be made</InputLabel>
            <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                autoWidth
                label="Payment Schedule Type"
                value={tenancyJson.paymentDueDate}
                onChange={(e)=>setTenancyJson({...tenancyJson,paymentDueDate: e.target.value})}
            >
                <MenuItem value={0}>Sunday</MenuItem>
                <MenuItem value={1}>Monday</MenuItem>
                <MenuItem value={2}>Tuesday</MenuItem>
                <MenuItem value={3}>Wednesday</MenuItem>
                <MenuItem value={4}>Thursday</MenuItem>
                <MenuItem value={5}>Friday</MenuItem>
                <MenuItem value={6}>Saturday</MenuItem>
            </Select>
        </FormControl>
    }
    </div>
        

      
       <div>
    <FormControl sx={{ m: 2, width: '25ch' }} variant="filled">
        <InputLabel>Rent</InputLabel>

        <FilledInput type="number"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={tenancyJson.rate}

            onChange={(e)=>setTenancyJson({...tenancyJson,rate: parseInt(e.target.value)})}
        />

    </FormControl>

    <FormControl sx={{ m: 2, width: '25ch' }} variant="filled">
        <InputLabel>Deposit</InputLabel>
        
        <FilledInput type='number'
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={tenancyJson.deposit}
            onChange={(e)=>setTenancyJson({...tenancyJson,deposit: parseInt(e.target.value)})}
        />
    </FormControl> 
    </div> 
    </Box>

    </>
  );
}